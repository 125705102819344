const AboutMe = () => {
  return (
    <section
      id="about"
      className="mb-16 scroll-mt-16 md:mb-24 lg:mb-36 lg:scroll-mt-24"
      aria-label="About Me"
    >
      <div className="sticky top-0 z-20 -mx-6 mb-4 w-screen bg-slate-900/75 px-6 py-5 backdrop-blur md:-mx-12 md:px-12 lg:sr-only lg:relative lg:top-auto lg:mx-auto lg:w-full lg:px-0 lg:py-0 lg:opacity-0">
        <h2 className="text-sm font-bold uppercase tracking-widest text-slate-200 lg:sr-only">
          About
        </h2>
      </div>
      <div>
        <p className="mb-4">
          Hello, I'm Vishnuvardhan Gorava – a passionate Full Stack Developer with a strong focus on Web Development and Database Design. I thrive in collaborative environments, quickly adapting to new technologies and challenges. My goal is to deliver high-quality, scalable solutions while working efficiently as part of a team. Let's build something great together!</p>
      </div>
    </section>
  );
};

export default AboutMe;
